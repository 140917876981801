/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    ol: "ol",
    li: "li",
    ul: "ul",
    pre: "pre",
    code: "code",
    em: "em",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "introduction-to-segment-tree",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction-to-segment-tree",
    "aria-label": "introduction to segment tree permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction to Segment Tree"), "\n", React.createElement(_components.p, null, "and program to construct a segment tree in Rust"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "The Segment Tree is a commonly used tree like data structure that is used for ", React.createElement(_components.strong, null, "range queries"), " or subarray queries, like minimum element or sum of elements in a given subarray."), "\n", React.createElement(_components.p, null, "By using the Segment Tree, we can reduce the time taken for each range query to ", React.createElement(_components.strong, null, "O( log n )"), ", that takes ", React.createElement(_components.strong, null, "O( n )"), " otherwise, but uses ", React.createElement(_components.strong, null, "O( n )"), " auxiliary space."), "\n", React.createElement(_components.p, null, "The example of range queries on a given array are :"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Sum/Product of integers in a given range."), "\n", React.createElement(_components.li, null, "Bitwise OR/AND of integers in given range."), "\n", React.createElement(_components.li, null, "Minimum/Maximum in a given range."), "\n", React.createElement(_components.li, null, "LCM/GCD in a given range."), "\n"), "\n", React.createElement(_components.p, null, "If you got any of these queries to perform in Logarithmic time, segment tree is a good option."), "\n", React.createElement(_components.h2, {
    id: "size-of-segment-tree",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#size-of-segment-tree",
    "aria-label": "size of segment tree permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Size of Segment Tree"), "\n", React.createElement(_components.p, null, "For a given array containing ", React.createElement(_components.strong, null, "n"), " integers, size of segment tree is"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "2n-1 : If n is a power of 2"), "\n", React.createElement(_components.li, null, "2k-1 : If n is not the power of 2, and k is the smallest power of 2 greater than n."), "\n"), "\n", React.createElement(_components.p, null, "So, for example, if n = 9 => k = 16, and hence, size of segment tree = 31."), "\n", React.createElement(_components.p, null, "Also, it is not hard to see that space complexity of a segment tree = ", React.createElement(_components.strong, null, "O( n )")), "\n", React.createElement(_components.p, null, "Here is the function to calculate the height of a Segment Tree in Rust"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn calc_size(n:usize)->usize{\n    // If n is a power of 2, return 2n-1\n    if n.count_ones() == 1 {\n        // Left shift is used to multiply n by 2.\n        return (n<<1)-1;\n    }\n\n    // k denotes the height of the tree.\n\n    // If n is not the power of 2, we have to take 2*k - 1\n    // k can be calculated by 2^(position of MSB+1)\n    // For example, for n = 7, position of MSB = 2, so k = 8\n    // Hence k = 2^(64 - leading zeroes)\n\n    let k = 1<<(64 - n.leading_zeros());\n    return (k<<1)-1;\n}\n")), "\n", React.createElement(_components.h2, {
    id: "structure-of-segment-tree",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#structure-of-segment-tree",
    "aria-label": "structure of segment tree permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Structure of Segment Tree"), "\n", React.createElement(_components.p, null, "The Segment Trees have a ", React.createElement(_components.strong, null, "Binary Tree"), " like structure, where each node has at most two children, each representing the left and right half of parent range respectively."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "For Example :"), " If a node represents the ", React.createElement(_components.em, null, "N"), " elements from [0..N-1] ( inclusive ), then its Left Child will represent the elements from [0..N/2-1] and right child will represent the elements from [N/2..N-1]."), "\n", React.createElement(_components.p, null, "Hence, we keep on dividing each node, until the node is representing a single element only."), "\n", React.createElement(_components.p, null, "Also, we store the elements in the array form. So, for ith node, its"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Left Node is represented by element at index 2*i +1"), "\n", React.createElement(_components.li, null, "Right Node is represented by element at index 2*i +2"), "\n"), "\n", React.createElement(_components.h2, {
    id: "constructing-a-segment-tree",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#constructing-a-segment-tree",
    "aria-label": "constructing a segment tree permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Constructing a segment tree"), "\n", React.createElement(_components.p, null, "Before we can perform our Range Queries, we have to construct a Segment Tree from the given array."), "\n", React.createElement(_components.h3, {
    id: "algorithm",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#algorithm",
    "aria-label": "algorithm permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Algorithm"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Calculate the size of segment tree and initialize an empty array/vector for segment tree with default value."), "\n", React.createElement(_components.li, null, "Initially, set low to 0, high to n-1 and position to 0, where size of original array is n."), "\n", React.createElement(_components.li, null, "If high is equal to low, then the current range represent only 1 element, and hence update it on the given position in the segment tree, and return."), "\n", React.createElement(_components.li, null, "If high is greater than low, then the current range represent 2 or more elements. So, we divide the range into 2 halves."), "\n", React.createElement(_components.li, null, "Recursively follow the steps 3 and 4 for left and right half of the range, for position 2×pos+1 and 2×pos+2 respectively."), "\n", React.createElement(_components.li, null, "Now, set the node at position to result of ", React.createElement(_components.strong, null, "desired operator"), " of left and right child."), "\n"), "\n", React.createElement(_components.h3, {
    id: "function",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function",
    "aria-label": "function permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function"), "\n", React.createElement(_components.p, null, "The program using above algorithm is given below. I used Multiplication operator for the reference."), "\n", React.createElement(_components.p, null, "All you have to do is call ", React.createElement(_components.code, null, "construct_segment_tree()"), " function, and pass the reference to the original array and ", React.createElement(_components.code, null, "n"), ", the size of the array."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " To change the operator, you can change the last line of ", React.createElement(_components.code, null, "construct_segment_tree_util()"), " function."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn calc_size(n:usize) ->usize{\n    if n.count_ones() == 1 { return (n<<1)-1; }\n    let k = 1<<(64 - n.leading_zeros());\n    return (k<<1)-1;\n}\n\n// I am taking Vector of unsigned integers.\n// You can take it as per your requirement\nfn construct_segment_tree(arr:&Vec<usize>) -> Vec<usize> {\n    // Calculate the size of the segment tree array first\n    let n = arr.len();\n    let s = calc_size(n);\n\n    // Initialize the segment tree as an array of size s, and all elements are 0\n    let mut segment_tree = vec![0 as usize; s];\n\n    // Now, call the construct_segment_tree_util function to build the tree\n    // Note : we use n-1 here, because we are using inclusive range.\n    construct_segment_tree_util(arr, &mut segment_tree, 0, n-1, 0);\n\n    return segment_tree;\n}\n\nfn construct_segment_tree_util(arr:&Vec<usize>, tree:&mut Vec<usize>, low:usize, high:usize, pos:usize){\n    // The range is empty\n    if high<low { return; }\n\n    // Only 1 element in the range.\n    // So, this is right position for the element\n    if high == low {\n        tree[pos] = arr[low];\n        return;\n    }\n\n    // Now, multiple elements exist in the given range.\n    // So, recursively perform this function for left and right half of range\n    let mid = (high+low)/2;\n\n    construct_segment_tree_util(arr, tree, low, mid, pos*2+1);\n    construct_segment_tree_util(arr, tree, mid+1, high, pos*2+2);\n\n    // Here, you can change operation as per requirement\n    // I am using multiplication operator for reference\n    tree[pos] = tree[pos*2+1]*tree[pos*2+2];\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( n )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( n )")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Segment tree is used frequently for Range Queries or Subarray Queries, especially in competitive programming."), "\n", React.createElement(_components.p, null, "It reduces the time complexity for each range query to ", React.createElement(_components.strong, null, "O( log n )"), " from ", React.createElement(_components.strong, null, "O( n )"), ", but uses ", React.createElement(_components.strong, null, "O( n )"), " auxiliary space.\nSo, when you have large number of range queries, you can easily use Segment tree to save time."), "\n", React.createElement(_components.p, null, "In this article, we saw the code to construct a Segment Tree in Rust. We will see Range Queries in the next article. Here is the optimized function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn cs(n:usize) ->usize{\n    if n.count_ones() == 1 { return (n<<1)-1; }\n    let k = 1<<(64 - n.leading_zeros());\n    return (k<<1)-1;\n}\n\nfn cons_st(arr:&Vec<usize>) -> Vec<usize> {\n    let n = arr.len();\n    let s = cs(n);\n    let mut segment_tree = vec![0 as usize; s];\n    cons_st_util(arr, &mut segment_tree, 0, n-1, 0);\n    return segment_tree;\n}\n\nfn cons_st_util(arr:&Vec<usize>, tree:&mut Vec<usize>, l:usize, h:usize, pos:usize){\n    if h<l { return; }\n    if h == l { tree[pos] = arr[l];return; }\n    let mid = (h+l)/2;\n    cons_st_util(arr, tree, l, mid, pos*2+1);\n    cons_st_util(arr, tree, mid+1, h, pos*2+2);\n\n    // Here, you can change operation as per requirement\n    // I am using product operator for reference\n    tree[pos] = tree[pos*2+1]*tree[pos*2+2];\n}\n\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Constructing a Segment Tree - Rust Programming",
    description: "Segment tree is used for processing Range Queries in logarithmic time complexity. In this article, we will learn about Segment Tree, and also write a function to construct a Segment Tree in Rust Language."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
